const GEN_SHARELINK_WINDOW_JS_URI = 'scripts/sharelink_window_4ai.js';
const GEN_ONE_SUBMIT_JS_URI = 'scripts/one_submit_4ai.js';
const GEN_SLIDER_JS_URI = 'scripts/slider.js';

const themePath = "/wp-content/themes/rmscourtage/dist/";

window.collections = {};

const SCRIPTS = [
    {
        'url': GEN_SHARELINK_WINDOW_JS_URI,
        'sel': '.share-links a',
        'col': 'sharelinks'
    },
    {
        'url': GEN_ONE_SUBMIT_JS_URI,
        'sel': 'form',
        'col': 'forms'
    },
    {
        'url' : GEN_SLIDER_JS_URI,
        'sel' : '.swipe-container',
        'col' : 'Slider' 
    }
];

let selectors = [];
for (let script of SCRIPTS) {
    selectors.push(script.sel);
}
const obj_present = Array.from(document.querySelectorAll(selectors.join(',')));

for (let script of SCRIPTS) {
    if (Array.from) {
        collections[script.col] = Array.from(document.querySelectorAll(script.sel));
        if (collections[script.col].length > 0) {
            if (script.lazy) {
                let encours = false,
                    observer = new IntersectionObserver(function (entries) {
                        for (let j = 0, lg = entries.length; j < lg; j++) {
                            if (entries[j].intersectionRatio <= 0 || encours) return;
                            encours = true;
                            new loadExt([themePath + script.url]);
                            for (let k = 0, lgt = entries.length; k < lgt; k++) {
                                observer.unobserve(entries[k].target);
                            }
                        }
                    }, obsOptions);

                for (let i = 0, l = collections[script.col].length; i < l; i++) {
                    observer.observe(collections[script.col][i]);
                }

            } else {
                new loadExt([themePath + script.url]);
            }
        }
    }
}

// FONCTIONS JS BATI-ARMOR
(function () {
    
    console.log('CssChallenge2 Child Scripts : Ready');

    

    // CHECK if safari browser
    var isIos = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isIos) {
        document.body.classList.add('safari');
    }


}) ();
